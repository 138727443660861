(function () {
'use strict';

angular.module('hyresgastApp').controller('EditAgreementRentlistCtrl', EditAgreementRentlistCtrl);

function EditAgreementRentlistCtrl($scope, $location, $route, $routeParams, $filter, $document, $timeout,
  Negotiation, Agreement, RealEstate,
  NegotiationTypes, ApartmentStatusTypes, FPConsts,
  HyUtils, Flash, ErrorHandler) {
  const S = ApartmentStatusTypes.STUCK.code;
  const M10 = ApartmentStatusTypes.M10.code;
  var vm = this;
  vm.getApartments = getApartments;
  vm.agreement = {
    negotiationId: $routeParams.negotiationId,
    id: $routeParams.id,
    documents: []
  };
  vm.selectedApartmentIds = {};
  vm.apartments = [];
  // statuses, that can be changed
  vm.validStatusTypes = FPConsts.agreeableApartmentStatuses;

  // statuses, that should be seen for negotiation
  vm.filterStatusTypes = [{ code: -1, name: 'Utan status' }]
    .concat(FPConsts.apartmentStatusesForNegotation);

  let index = 0;
  vm.filterStatusTypes.forEach(x => x.id = index++);
  vm.inputStatuses = [...vm.filterStatusTypes];
  vm.listColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Fastighet', column: 'property.propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'Normhyra', column: 'nh' },
    { title: 'Hyra (kr/månad) nu', column: 'currentMonthlyRent' },
    { title: 'Strandad nu', column: 'currentIsBlocked' },
    { title: 'Förändring/mån', column: 'changeDescription' }
  ];
  vm.nh = {
    items: [
      { id: 'nh91', text: '-91' },
      { id: 'nh78', text: '-78' },
      { id: 'nh12', text: '-12' },
    ],
  };
  vm.nh.current = vm.nh.items[0];
  vm.sortColumn = vm.listColumns.some(c => c.column === $location.search().order)
    ? $location.search().order
    : 'currentIsBlocked'; // default
  vm.sortDirection = $location.search().direction || 'DESC'; // default
  vm.pageLimit = 30;
  vm.offset = 0;
  vm.firstRecordInCurrentPage = 1;
  vm.isDirty = false;

  $scope.$on('$routeUpdate', () => {
    if ($location.search().order) {
      // reset offset when sorting
      vm.offset = 0;
      getApartments();
    }
  });

  function getNegotiation() {
    return Negotiation.get({
      id: $routeParams.negotiationId
    })
      .$promise.then(function(response) {
        vm.isNewConstruction = response.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY
        || response.type === NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT;

      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function() {
        vm.readyNegotiation = true;
      });
  }

  vm.filterNH = () => {
    let focus = element => {
      if (element) {
        element.focus();
        element.select();
      }
    };
    if (!vm.inputNHMin && vm.inputNHMax) {
      focus($document[0].getElementById('inputNHMin'));
    } else if (!vm.inputNHMax && vm.inputNHMin) {
      focus($document[0].getElementById('inputNHMax'));
    } else {
      getApartments();
    }
  };

  function getApartments(readyCb) {
    vm.readyApartments = false;
    let columnHints = [{
      name: 'streetAddress',
      ft: false,
    }];
    Agreement.queryApartment({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[nationalNo]': vm.inputTextNationalApartmentNo,
      'search[ownerNo]': vm.inputTextApartmentNo,
      'search[streetAddress]': vm.inputTextStreetAddress,
      'search[property.propertyDesignation]': vm.inputTextPropertyDesignation,
      'search[municipality]': vm.inputTextMunicipality,
      'filters[rooms]': vm.inputTextRooms,
      'filters[livingSpace]': vm.inputTextLivingSpace,
      columnHints: JSON.stringify(columnHints),
    }).$promise.then(response => {
      vm.offset = 0;
      vm.apartments = response.rows;

      vm.apartments.forEach(a => {
        vm.selectedApartmentIds[a.id] = true;
        a.currentIsBlocked = (a.currentStatus & S) === S;
        if (!a.status) {
          a.status = a.currentStatus;
        }
      });

      getNewNH(() => {

        if (vm.inputNHMin && vm.inputNHMax) {
          vm.apartments = vm.apartments.filter(a => {
            let fitInCurrent = (a[vm.nh.current.id] >= vm.inputNHMin) && (a[vm.nh.current.id] <= vm.inputNHMax);
            let fitInProposed = (a[vm.nh.id] >= vm.inputNHMin) && (a[vm.nh.id] <= vm.inputNHMax);
            return fitInCurrent || fitInProposed;
          });
        }
        const checkStatus = status => {
          let ok = false;
          if (vm.inputStatuses.find(x => x.code === -1)) {
            ok = FPConsts.apartmentStatusesForNegotation.every(x => (status & x.code) !== x.code);
          }
          if (vm.inputStatuses.find(x => x.code !== -1)) {
            ok |= !!vm.inputStatuses.find(x => (status & x.code) === x.code);
          }
          return ok;
        };
        if (vm.inputStatuses.length) {
          vm.apartments = vm.apartments.filter(a => checkStatus(a.status) || checkStatus(a.currentStatus));
        }

        let specialSortColumn = ['currentIsBlocked', 'nh'].find(x => x === vm.sortColumn);
        if (specialSortColumn) {
          let desc = vm.sortDirection !== 'ASC';
          specialSortColumn = specialSortColumn === 'nh' ? vm.nh.current.id : specialSortColumn;
          vm.apartments = vm.apartments.sort((a, b) => desc
            ? (b[specialSortColumn] - a[specialSortColumn])
            : (a[specialSortColumn] - b[specialSortColumn]));
        }

        vm.apartmentsTotalCount = response.count;
        vm.allApartmentsBoxChecked = true;
        vm.isDirty = false;
        vm.hasNewRents = vm.apartments.some(a => a.newMonthlyRent);
        vm.hasNewStatuses = vm.apartments.some(a => a.currentStatus !== a.status);
        if (readyCb) {
          readyCb();
        } else {
          vm.setSlice();
        }
      });
    }).catch(new ErrorHandler('Misslyckades att hämta fastighetsbestånd'))
      .finally(() => { vm.readyApartments = true });
  }

  vm.setSlice = () => {
    vm.apartmentsSlice = vm.apartments.slice(vm.offset, vm.offset + vm.pageLimit);
  };

  vm.paginatorGetApartments = () => {
    if (!vm.readyApartments) {
      getApartments();
    } else {
      vm.setSlice();
    }
  };

  vm.toggleAllApartmentsSelection = () => {
    vm.apartments.forEach(apartment => {
      if (vm.selectedApartmentIds[apartment.id] !== null) {
        vm.selectedApartmentIds[apartment.id] = vm.allApartmentsBoxChecked;
      }
    });
  };

  vm.updateAllPropertiesBoxState = () => {
    // auto select master checkbox if all other checkboxes are selected
    // deselect master checkbox if at least one checkbox is not selected
    vm.allApartmentsBoxChecked = Object.keys(vm.selectedApartmentIds)
      .map(apartmentId => vm.selectedApartmentIds[apartmentId] === true || vm.selectedApartmentIds[apartmentId] === null)
      .every(isSelected => isSelected);
  };

  vm.atLeastOneSelectedApartmentId = () => Object.keys(vm.selectedApartmentIds)
    .map(apartmentId => vm.selectedApartmentIds[apartmentId] === true)
    .some(isSelected => isSelected);


  let getSelectedRows = () => vm.apartments.filter(apartment => vm.selectedApartmentIds[apartment.id] === true);

  let isS = status => status ? ((status & S) === S) : false;
  vm.countSelectedS = () => getSelectedRows()
    .filter(x => isS(x.status)).length;
  vm.canSetRent = () => {
    //3. if apartment has S - rent setting is not possible
    let hasS = getSelectedRows().some(x => isS(x.status));
    return !hasS;
  };
  vm.canSetStatus = () => {
    let hasNewRent = getSelectedRows()
      .some(x => x.newMonthlyRent && (x.newMonthlyRent !== x.monthlyRent));
    return !hasNewRent;
  };

  let getNewYearlyRent = apartment => apartment.newMonthlyRent * (((apartment.status & M10) === M10) ? 10 : 12);

  let getNewNH = readyCb => {
    RealEstate.calculateNH(vm.apartments.map(a => ({
      id: a.id,
      rooms: a.rooms,
      livingSpace: a.livingSpace,
      kitchenType: a.kitchenType,
      yearlyRent: getNewYearlyRent(a),
    }))).$promise.then(response => {
      vm.apartments.forEach(a => a.newNH = response.rows.find(r => r.id === a.id));
      if (readyCb) {
        readyCb();
      }
    });
  };

  vm.editRentalLevels = () => {
    let plusDipstick = (desc, dipstick) => (desc ? `${desc} +` : '') + dipstick;
    let plusDipstickZero = (desc, dipstick) => desc && (desc.includes('+0 %') || desc === dipstick)
      ? desc
      : plusDipstick(desc, dipstick);
    let percentMultiplier = percent => 1 + (parseFloat(percent) / 100);
    let setZero = apartment => {
      angular.extend(apartment, {
        newMonthlyRent: apartment.currentMonthlyRent,
        changeDescription: plusDipstickZero(apartment.changeDescription, `${0} %`),
      });
    };

    let selectedRows = getSelectedRows();
    let nhRange = { min: 9999999, max: -99999999, table: vm.nh.current.text };
    selectedRows.forEach(row => {
      nhRange.min = Math.min(nhRange.min, row[vm.nh.current.id]);
      nhRange.max = Math.max(nhRange.max, row[vm.nh.current.id]);
    });
    if (vm.countSelectedS() === selectedRows.length) {
      selectedRows.forEach(apartment => setZero(apartment));
      vm.isDirty = true;
      getNewNH();
      return;
    }
    HyUtils.showDefaultModal('EditRentalLevelModalCtrl',
      'resources/agreement/rentlist/edit/edit-rental-level-modal.html', {
        isNewConstruction: vm.isNewConstruction,
        nhRange
      }, result => {
        selectedRows.forEach(apartment => {
          if (isS(apartment.status)) {
            setZero(apartment);
            return;
          }
          switch (result.changeMethod) {
            case 0: //percent
              var percent = result.percent;
              if (result.percentByNhRange) {
                let rangeMatch = result.percentByNhRange.find(r => (apartment[vm.nh.current.id] >= r.min) &&
                  (apartment[vm.nh.current.id] <= r.max));
                if (rangeMatch) {
                  percent = rangeMatch.number;
                } else {
                  return;
                }
              }
              var multiplier = percentMultiplier(percent);
              angular.extend(apartment, {
                newMonthlyRent: apartment.newMonthlyRent
                  ? apartment.newMonthlyRent * multiplier
                  : apartment.currentMonthlyRent * multiplier,
                changeDescription: plusDipstick(apartment.changeDescription, `${percent} %`)
              });
              break;
            case 1: //kr
              if (result.isRelative) {
                angular.extend(apartment, {
                  newMonthlyRent: apartment.newMonthlyRent
                    ? apartment.newMonthlyRent + result.monthly
                    : apartment.currentMonthlyRent + result.monthly,
                  changeDescription: plusDipstick(apartment.changeDescription, `${result.monthly} kr`)
                });
              } else {
                angular.extend(apartment, {
                  newMonthlyRent: result.monthly,
                  changeDescription: ''
                });
              }
              break;
            case 2: //krm2
              // TODO: consider using unificated math function for rounding
              var rent = Math.round((result.monthly * apartment.livingSpace + Number.EPSILON) * 100) / 100;
              if (result.isRelative) {
                angular.extend(apartment, {
                  newMonthlyRent: apartment.newMonthlyRent
                    ? apartment.newMonthlyRent + rent
                    : apartment.currentMonthlyRent + rent,
                  changeDescription: plusDipstick(apartment.changeDescription, `${rent} kr`)
                });
              } else {
                angular.extend(apartment, {
                  newMonthlyRent: rent,
                  changeDescription: ''
                });
              }
              break;
          }
        });
        getNewNH();
        vm.isDirty = true;
        $timeout(() => $scope.$apply(), 200);
      }, null, null, { size: 'md' });
  };

  vm.editComment = apartment => {
    HyUtils.showDefaultModal('EditCommentModalCtrl',
      'resources/agreement/rentlist/edit/edit-comment-modal.html',
      { comment: apartment.comment, multipleComments: '' },
      result => {
        if (result.delete || result.comment) {
          apartment.comment = result.delete ? '' : result.comment;
          vm.isDirty = true;
        }
      }, null, null,
      { size: 'md' });
  };

  vm.massComment = () => {
    // eslint-disable-next-line no-undef
    let comments = new Set(vm.apartments.map(a => a.comment).filter(a => a));
    let comment = comments.size === 1 ? Array.from(comments)[0] : '';
    let multipleComments = comments.size > 1 ? Array.from(comments).join('; ') : null;
    HyUtils.showDefaultModal('EditCommentModalCtrl',
      'resources/agreement/rentlist/edit/edit-comment-modal.html',
      { comment, multipleComments },
      result => {
        if (result.delete || result.comment) {
          let selectedRows = getSelectedRows();
          let newComment = result.delete ? '' : result.comment;
          selectedRows.forEach(apartment => { apartment.comment = newComment });
          vm.isDirty = true;
        }
      }, null, null,
      { size: 'md' });
  }

  vm.editObjects = () => {
    let selectedRows = getSelectedRows();
    selectedRows.forEach(a => a.oldSum = vm.getExtraObjectsCost(a.objectsExtra));
    // eslint-disable-next-line no-undef
    let currentObjects = new Set(selectedRows.map(a => a.currentObjectsExtra).filter(a => a));
    // eslint-disable-next-line no-undef
    let proposedObjects = new Set(selectedRows.map(a => a.objectsExtra).filter(a => a));
    let params = {
      objects: proposedObjects.size === 1 ? JSON.parse([...proposedObjects][0], HyUtils.dtRev) : [],
      oldObjects: currentObjects.size === 1 ? JSON.parse([...currentObjects][0], HyUtils.dtRev) : [],
    };
    HyUtils.showDefaultModal('EditObjectsModalCtrl',
      'resources/agreement/rentlist/edit/edit-objects-modal.html', params,
      result => {
        if (result.delete) {
          selectedRows.forEach(apartment => {
            apartment.newMonthlyRent -= apartment.oldSum;
            apartment.objectsExtra = null;
          });
        } else {
          selectedRows.forEach(apartment => {
            apartment.objectsExtra = JSON.stringify(result.objects);
            let newSum = vm.getExtraObjectsCost(apartment.objectsExtra);
            if (!apartment.newMonthlyRent || apartment.newMonthlyRent === 0) {
              apartment.newMonthlyRent = apartment.currentMonthlyRent;
            }
            apartment.newMonthlyRent -= apartment.oldSum;
            apartment.newMonthlyRent += newSum;
            delete apartment.oldSum;
          });
        }
        vm.isDirty = true;
      });
  };

  vm.editStatus = () => {
    let selectedRows = getSelectedRows();
    HyUtils.showDefaultModal('EditStatusModalCtrl', 'resources/agreement/rentlist/edit/edit-status-modal.html', {
      validStatuses: vm.validStatusTypes,
      currentStatuses: selectedRows.map(x => x.currentStatus)
    }, result => {
      selectedRows.forEach(row => {
        if (result.set) {
          row.status |= result.code;
        }
        if (result.reset) {
          row.status &= ~result.code;
        }
        if ((result.set || result.reset) && !row.newMonthlyRent) {
          row.newMonthlyRent = row.currentMonthlyRent;
        }
      });
      vm.isDirty = true;
    }, null, null, { size: 'sm' });
  };

  vm.revert = () => {
    getApartments();
    vm.isDirty = false;
  };

  vm.startOver = (isCorrection) => {
    let selectedApartments = vm.apartments.filter(a => vm.selectedApartmentIds[a.id] === true);
    selectedApartments.forEach(a => {
      a.newMonthlyRent = isCorrection ? a.currentMonthlyRent : null;
      a.changeDescription = null;
      a.status = a.currentStatus;
      a.objectsExtra = a.currentObjectsExtra;
    });
    if (selectedApartments.length) {
      vm.isDirty = false;
      vm.save();
    }
  };

  vm.startOverCorrection = () => {
    let selectedApartments = vm.apartments.filter(a => vm.selectedApartmentIds[a.id] === true);
    let params = (selectedApartments.length === vm.apartments.length)
      ? { agreementId: vm.agreement.id }
      : {
        agreementId: vm.agreement.id,
        apartmentIds: selectedApartments.map(x => x.id),
      };
    Agreement.resetCorrection(params).$promise.then(() => { $route.reload() });
  };

  vm.save = () => {
    vm.saving = true;
    Agreement.editRentlist({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id,
    }, { apartments: vm.apartments, pako: true })
      .$promise.then(() => Flash.set('Lyckades att spara överenskommelse.', 'success'))
      .catch(new ErrorHandler('Misslyckades att spara överenskommelse.'))
      .finally(() => {
        getApartments(() => {
          vm.offset = 0;
          vm.firstRecordInCurrentPage = 1;
          vm.saving = false;
          vm.isDirty = false;
          vm.paginatorGetApartments();
        });
      });
  };

  /**
   * get fancy monthly rent
   * @param {object} apartment
   * @returns {string} fancy monthly rent
   */
  vm.getMonthlyRent = (apartment, raw) => {
    let rent;
    if (apartment.currentMonthlyRent) {
      rent = apartment.currentMonthlyRent;
    } else {
      if (apartment.currentYearlyRent) {
        const monthlyRent = apartment.currentYearlyRent / 12;
        apartment.currentMonthlyRent = monthlyRent;
        rent = monthlyRent;
      } else {
        rent = Number.NaN;
      }
    }

    let text = Number.isNaN(rent) ? 'Ej angivet' : $filter('number')(rent, 0);
    return raw ? rent : text;
  };

  /**
   * counts sum of all extras
   * @param {string} objectsExtraJson
   * @returns {number}
   */
  vm.getExtraObjectsCost = objectsExtraJson => {
    try {
      let objects = JSON.parse(objectsExtraJson);
      return objects.map(o => o.value).reduce((acc, el) => (acc += el), 0);
    } catch (e) {
      return 0;
    }
  };

  //START
  getNegotiation();
  vm.paginatorGetApartments();
}

})();