(function () {
'use strict';

angular.module('hyresgastApp').controller('ListBlockingApartmentsCtrl', ListBlockingApartmentsCtrl);

function ListBlockingApartmentsCtrl($scope, $routeParams, $location, Blocking, Auth, Flash, ErrorHandler) {
  var vm = this;
  vm.apartmentsPageLimit = 30;
  vm.apartmentsPageOffset = 0;

  vm.delete = destroy;

  vm.getApartments = getApartments;
  vm.sortColumn = $location.search().order || 'property.propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default
  vm.listColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Fastighet', column: 'property.propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'Ingående hyra (kr/månad)', column: 'rent.monthlyRent' }
  ];

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.apartmentsPageOffset = 0;
      getApartments();
    }
  });

  getApartments();

  /////////

  function getApartments(filterMode) {
    vm.readyApartments = false;
    if (filterMode) {
      vm.apartmentsPageOffset = 0;
    }
    return Blocking.queryApartment({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id,
      limit: vm.apartmentsPageLimit,
      offset: vm.apartmentsPageOffset,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[nationalNo]': vm.inputTextNationalApartmentNo,
      'search[ownerNo]': vm.inputTextApartmentNo,
      'search[streetAddress]': vm.inputTextStreetAddress,
      'search[property.propertyDesignation]': vm.inputTextPropertyDesignation,
      'search[municipality]': vm.inputTextMunicipality
    })
      .$promise.then(function(response) {
        vm.apartments = response.rows;
        vm.apartmentsTotalCount = response.count;
        // Save initial count of records, to be distingueshed from the current count after
        // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
        if (filterMode !== true) {
          vm.initialTotalCount = vm.apartmentsTotalCount;
        }
        // open the wizard when apartments' list is empty
        if (vm.initialTotalCount === 0) {
          $scope.$parent.vm.editApartmentMode = true;
        }
      })
      .catch(new ErrorHandler('Misslyckades att hämta fastighetsbestånd.'))
      .finally(function() {
        vm.readyApartments = true;
      });
  }

  function destroy(property) {
    // if wizard is open close it so it will updated
    // with correct list when re-opened
    $scope.$parent.vm.editApartmentMode = false;
    vm.deletingApartment = true;
    return Blocking.deleteApartment({
      negotiationId: $routeParams.negotiationId,
      blockingId: $routeParams.id,
      id: property.id
    })
      // eslint-disable-next-line no-unused-vars
      .$promise.then(function(response) {
        var index = vm.apartments.indexOf(property);
        vm.apartments.splice(index, 1);
        vm.apartmentsTotalCount--;
        $scope.$parent.vm.blocking.numApartments = vm.apartmentsTotalCount;
        // i.e interested in notAssignedCount in negotiations
        // object to know if to show or hide buttons
        $scope.$parent.vm.getNegotiation();
        vm.initialTotalCount = vm.apartmentsTotalCount;
        if (vm.initialTotalCount === 0) {
          $scope.$parent.vm.editApartmentMode = true;
        }
        Flash.set('Lyckades att ta bort lägenhet', 'success');
      })
      .catch(new ErrorHandler('Misslyckades att ta bort lägenhet.'))
      .finally(function() {
        vm.deletingApartment = false;
      });
  }
}

})();