(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowBlockingCtrl', ShowBlockingCtrl);

function ShowBlockingCtrl($scope, $routeParams, Auth, Blocking, Flash, Negotiation, NegotiationStates, ErrorHandler) {
  var vm = this;
  vm.NegotiationStates = NegotiationStates;
  vm.skipRentlist = false;

  vm.getNegotiation = getNegotiation;
  getBlocking();
  getNegotiation();

  Auth.heartbeat().then(function() {
    vm.currentUserParty = Auth.currentUser().party;
  });

  ////////

  function getNegotiation() {
    return Negotiation.get({
      id: $routeParams.negotiationId
    })
      .$promise.then(function(response) {
        vm.negotiation = response;

        vm.negotiation.isFinished = vm.negotiation.state === NegotiationStates.FINISHED ||
          vm.negotiation.state === NegotiationStates.FINISHED_OUTSIDE_PORTAL;

        vm.skipRentlist = vm.negotiation.canSkipRentlist;
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function() {
        vm.readyNegotiation = true;
      });
  }

  function getBlocking() {
    return Blocking.get({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id
    })
      .$promise.then(function(response) {
        vm.blocking = response.agreement;
      })
      .catch(new ErrorHandler('Misslyckades att hämta strandning.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();