(function () {
'use strict';

angular.module('hyresgastApp').controller('EditBlockingPropertiesCtrl', EditBlockingPropertiesCtrl);

function EditBlockingPropertiesCtrl($scope, $route, $routeParams, $location, $timeout, Negotiation,
  Blocking, Flash, ErrorHandler) {
  var vm = this;

  vm.blocking = {
    negotiationId: $routeParams.negotiationId,
    id: $routeParams.id,
    blockedBy: $location.search().blockedBy,
    documents: [],
    name: '', // there is a not null constraing on api
    isAllPropertiesSelected: true,
  };

  vm.save = save;

  // Fetch properties when the user wants to sort by column or direction
  $scope.$watchGroup(['vm.sortColumn', 'vm.sortDirection'], function () {
    // Check for undefined 'order' parameter in the URL
    if ($location.search().order) {
      // Reset the offset when changing column or direction of sorting
      vm.pageOffset = 0;
      getProperties();
    }
  });

  vm.pageOffset = 0;
  vm.pageLimit = 30;
  vm.blocking.selectedPropertyIds = {};
  vm.getProperties = getProperties;

  vm.listColumns = [
    { title: 'Fastigheter', column: 'propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' },
    { title: 'Antal lägenheter', column: 'numNegotiatedApartments' }
  ];
  vm.sortColumn = getLocationOrderIfValid(vm.listColumns) || 'propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  getProperties();
  getNegotiation();

  // returns sort column from url parameter ($location.search().order) if it's included in the given
  // list of column definitions; null otherwise
  function getLocationOrderIfValid(allowedColumns) {
    if (
      allowedColumns.some(function (c) {
        return c.column === $location.search().order;
      })
    ) {
      return $location.search().order;
    }
    return null;
  }

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getProperties();
    }
  });

  ////////////

  function save() {
    vm.saving = true;
    Blocking.saveProperty(vm.blocking)
      .$promise.then(
        // eslint-disable-next-line no-unused-vars
        function (response) {
          $location.search({}); // remove parameters set by wizard
          $location.path('/negotiations/' + vm.blocking.negotiationId).search('view', 'agreements');
          Flash.set('Fastigheter i förhandlingen har strandats', 'success');
        })
      .catch(new ErrorHandler('Misslyckades att stranda förhandlingen.'))
      .finally(function () {
        vm.saving = false;
      });
  }

  function getNegotiation() {
    return Negotiation.get({
      id: $routeParams.negotiationId
    })
      .$promise.then(function (response) {
        vm.negotiation = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function () {
        vm.readyNegotiation = true;
      });
  }

  vm.getPropertiesVirtual = () => {
    vm.propertiesVirtual = vm.properties.slice(vm.pageOffset, vm.pageOffset + vm.pageLimit);
  };
  function getProperties(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }
    return Negotiation.queryPropertyBySelection(
      {
        id: vm.blocking.negotiationId,
        limit: 2000,
        offset: 0,
        order: vm.sortColumn,
        direction: vm.sortDirection,
        'search[propertyDesignation]': vm.inputPropertiesTextProperty,
        'search[ownerName]': vm.inputPropertiesTextOwner,
        'search[municipality]': vm.inputPropertiesTextMunicipality,
        'search[district]': vm.inputPropertiesTextDistrict,
        include: ['owners']
      },
      {
        unassigned: true,
        selectedDistrictIds: {},
        isAllDistrictsSelected: true,
      }
    )
      .$promise.then(function (response) {
        if (vm.propertiesTotalCount !== response.count) {
          vm.blocking.selectedPropertyIds = {};
        }
        vm.properties = response.rows;
        vm.getPropertiesVirtual();
        vm.propertiesTotalCount = response.count;
      })
      .catch(new ErrorHandler('Misslyckades att hämta fastigheter.'))
      .finally(function () {
        vm.ready = true;
      });
  }
}
})();