(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationAgreementCtrl', ShowNegotiationAgreementCtrl);

function ShowNegotiationAgreementCtrl($scope, $location, $routeParams, $uibModal, $timeout,
  Negotiation, Agreement, Blocking,
  AgreementTypes, Parties, AgreementBlockedByType, NegotiationTypes,
  Auth, Flash, ErrorHandler, HyUtils) {

  //this controller was expected to only fetch agreements, however now we fetch blocking and apartment history also
  var vm = this;

  vm.parentCtrlVM = $scope.$parent.vm;
  vm.AgreementBlockedByType = AgreementBlockedByType;
  vm.AgreementTypes = AgreementTypes;
  vm.gotoShowAgreement = gotoShowAgreement;
  vm.gotoShowBlocking = gotoShowBlocking;
  vm.resume = resume;
  vm.createAgreement = createAgreement;
  vm.acceptBlockedAgreement = acceptBlockedAgreement;
  vm.blockAgreement = blockAgreement;

  Auth.heartbeat().then(function() {
    vm.currentUserParty = Auth.currentUser().party;
    if (vm.currentUserParty === Parties.HGF) {
      vm.isHgfUser = true;
    }
  });

  getAgreements();
  getBlockings();

  /////////

  function getAgreements() {
    return Agreement.query({ negotiationId: $routeParams.id })
      .$promise.then(function(response) {
        vm.agreements = response;
        vm.agreements
          .filter(a => a.conditionTags)
          .forEach(a => a.conditionTags = JSON.parse(a.conditionTags));

        vm.agreements.sort((a, b) => a.sequenceNumber - b.sequenceNumber);

        // eslint-disable-next-line no-undef
        const map = new Map();
        vm.agreements.forEach(obj => {
          // eslint-disable-next-line no-undefined
          if (obj.conditionTags.revertSourceId !== undefined) {
            map.set(parseInt(obj.conditionTags.revertSourceId), parseInt(obj.id));
          }
        });

        const result = [];
        // eslint-disable-next-line no-undef
        const visited = new Set();

        vm.agreements.forEach(obj => {
          if (!visited.has(obj.id)) {
            result.push(obj);
            visited.add(obj.id);
            if (map.has(obj.id)) {
              const descendantId = map.get(obj.id);
              const descendant = vm.agreements.find(o => o.id === descendantId);
              if (descendant) {
                result.push(descendant);
                visited.add(descendant.id);
              }
            }
          }
        });

        vm.agreements = result;
      })
      .catch(new ErrorHandler('Misslyckades att hämta överenskommelser.'))
      .finally(function() {
        vm.readyAgreements = true;
      });
  }

  function getBlockings() {
    return Blocking.query({ negotiationId: $routeParams.id })
      .$promise.then(function(response) {
        vm.blockings = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta strandningar.'))
      .finally(function() {
        vm.readyBlockings = true;
      });
  }

  function refreshNegotiation() {
    return Negotiation.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.parentCtrlVM.negotiation = response;
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'));
  }

  function resume($event, blocking) {
    // dont want to propagate to parent ng-click which would redirect
    // user to blocking show page
    $event.stopPropagation();

    return Blocking.resume({
      negotiationId: $routeParams.id,
      id: blocking.id
    })
      .$promise.then(function() {
        // Remove item from blockings list
        var index = vm.blockings.indexOf(blocking);
        vm.blockings.splice(index, 1);
        refreshNegotiation();
        Flash.set('Lyckades återuppta strandning', 'success');
      })
      .catch(new ErrorHandler('Misslyckades att återuppta strandning.'));
  }

  function gotoShowAgreement(id) {
    $location.search({});
    $location.path('/negotiations/' + $routeParams.id + '/agreements/' + id);
  }

  function gotoShowBlocking(id) {
    $location.search({});
    $location.path('/negotiations/' + $routeParams.id + '/blockings/' + id);
  }

  vm.revertAgreement = agreementId => {
    vm.busyRevertingAgreement = true;
    HyUtils.askQuestion('Vill du verkligen skriva en rättelse som ersätter tidigare överenskommelse?').then(answer => {
      if (answer === 'Ja') {
        $timeout(() => {
          Agreement.revertAgreement({ agreementId })
            .$promise.then(response => { gotoShowAgreement(response.newAgreementId) })
            .catch(() => { Flash.set('Misslyckades att skapa rättelse', 'error') });
        }, 300);
      } else {
        vm.busyRevertingAgreement = false;
      }
    });
  };

  function createAgreement () {
    let negotiation = vm.parentCtrlVM.negotiation;
    let newRealEstate = negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY ||
      negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT;
    let negotiationConditionTags = negotiation.conditionTags ? JSON.parse(negotiation.conditionTags) : {}

    if (!newRealEstate && Auth.isExternalUser()) {
      _createAgreement({ isReadyToSend: true });
    } else {
      HyUtils.showDefaultModal('ConditionsModalCtrl',
        'resources/negotiation/show/agreements/modal/conditions.html',
        {
          newRealEstate,
          isReadyToSend: negotiationConditionTags.isReadyToSend,
        },
        result => {
          let conditionTags = {
            isReadyToSend: Auth.isExternalUser() ? true : result.isReadyToSend,
          };
          if (newRealEstate) {
            conditionTags.isPresumtiveRent = result.isPresumtiveRent;
            conditionTags.isInvestmentProperty = result.isInvestmentProperty;
          }
          _createAgreement(conditionTags);
        },
        null, null, { size: 'md' }
      );
    }
  }

  function _createAgreement (conditionTags) {
    vm.savingAgreement = true;
    let agreement = {
      negotiationId: $routeParams.id,
      name: '', // backend requires it
      conditionTags: JSON.stringify(conditionTags),
    };

    Agreement.save(agreement)
      .$promise.then(
        function(response) {
          Flash.set('Överenskommelse har skapats', 'success');
          $location.search({});
          $location.path('/negotiations/' + $routeParams.id + '/agreements/' + response.agreement.id)
        })
      .catch(function() {
        Flash.set('Misslyckades att skapa överenskommelse', 'error')
      })
      .finally(function() {
        vm.savingAgreement = false;
      });
  }

  function acceptBlockedAgreement(blocking) {
    var settings = {
      subtitle: 'Är du säker på att du vill gå vidare?',
      showDropdown: false
    };
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'resources/negotiation/show/agreements/modal/confirmationBlockedModal.html',
      controller: 'ConfirmationBlockedModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });
    modalInstance.result.then(function() {
      if (vm.isHgfUser) {
        blocking.agreedByAssociation = true;
      } else {
        blocking.agreedByOwner = true;
      }
      Blocking.save(blocking)
        .$promise.then(function() {
          if (blocking.agreedByAssociation && blocking.agreedByOwner) {
            blocking.isAgreed = true;
          }
        })
        .catch(new ErrorHandler('Misslyckades att spara strandning.'));
    });
  }

  function blockAgreement() {
    var settings = {
      subtitle: 'Är du säker på att du vill stranda lägenheter?',
      showDropdown: true
    };

    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      templateUrl: 'resources/negotiation/show/agreements/modal/confirmationBlockedModal.html',
      controller: 'ConfirmationBlockedModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        settings: function() {
          return settings;
        }
      }
    });
    modalInstance.result.then(function(blockedBy) {
      let path = vm.parentCtrlVM.negotiation.canSkipRentlist === true ? '/negotiations/' + $routeParams.id + '/blockings/select-property'
        : '/negotiations/' + $routeParams.id + '/blockings/new';
      $location.path(path).search({ blockedBy: blockedBy });
    });
  }
}

})();