(function () {
'use strict';

angular.module('hyresgastApp').factory('Blocking', function($resource) {
  return $resource(
    '/api/negotiations/:negotiationId/blockings/:id',
    { negotiationId: '@negotiationId', blockingId: '@blockingId', id: '@id' },
    {
      queryApartment: {
        url: '/api/negotiations/:negotiationId/blockings/:id/apartments'
      },
      deleteApartment: {
        url: '/api/negotiations/:negotiationId/blockings/:blockingId/apartments/:id/unassign',
        method: 'POST'
      },
      saveApartment: {
        url: '/api/negotiations/:negotiationId/blockings/:id/apartments',
        method: 'POST'
      },
      resume: {
        url: '/api/negotiations/:negotiationId/blockings/:id/resume',
        method: 'POST'
      },
      queryProperty: {
        url: '/api/negotiations/:negotiationId/blockings/:id/properties'
      },
      saveProperty: {
        url: '/api/negotiations/:negotiationId/blockings/:id/properties',
        method: 'POST'
      },
      deleteProperty: {
        url: '/api/negotiations/:negotiationId/blockings/:blockingId/properties/:id/unassign',
        method: 'POST'
      },
    }
  );
});

})();