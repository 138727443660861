(function () {
'use strict';

angular.module('hyresgastApp').controller('NewBlockingCtrl', NewBlockingCtrl);

function NewBlockingCtrl($scope, $location, $routeParams, Blocking, Negotiation, Organization, Flash, ErrorHandler) {
  var vm = this;

  vm.blocking = {
    negotiationId: $routeParams.id,
    blockedBy: $location.search().blockedBy,
    documents: [],
    name: '', // there is a not null constraing on api
    isAllDistrictsSelected: true,
    isAllPropertiesSelected: true,
    isAllApartmentsSelected: true
  };

  vm.gotoNextStep = gotoNextStep;
  vm.save = save;

  // Fetch districts when the user wants to sort by column or direction
  $scope.$watchGroup(['vm.sortDistrictColumn', 'vm.sortDistrictDirection'], function() {
    // Check for undefined 'order' parameter in the URL
    if ($location.search().order) {
      // Reset the offset when changing column or direction of sorting
      vm.districtPageOffset = 0;
      queryDistrict();
    }
  });

  // Fetch properties when the user wants to sort by column or direction
  $scope.$watchGroup(['vm.sortPropertiesColumn', 'vm.sortPropertiesDirection'], function() {
    // Check for undefined 'order' parameter in the URL
    if ($location.search().order) {
      // Reset the offset when changing column or direction of sorting
      vm.propertiesPageOffset = 0;
      queryProperties();
    }
  });

  // Fetch apartments when the user wants to sort by column or direction
  $scope.$watchGroup(['vm.sortApartmentsColumn', 'vm.sortApartmentsDirection'], function() {
    // Check for undefined 'order' parameter in the URL
    if ($location.search().order) {
      // Reset the offset when changing column or direction of sorting
      vm.apartmentsPageOffset = 0;
      queryApartments();
    }
  });

  vm.districtPageOffset = 0;
  vm.propertiesPageOffset = 0;
  vm.apartmentsPageOffset = 0;
  vm.propertiesPageLimit = 30;
  vm.districtPageLimit = 30;
  vm.apartmentsPageLimit = 30;
  vm.blocking.selectedDistrictIds = {};
  vm.blocking.selectedPropertyIds = {};
  vm.blocking.selectedApartmentIds = {};
  vm.queryProperties = queryProperties;
  vm.queryDistrict = queryDistrict;
  vm.queryApartments = queryApartments;

  vm.sortPropertiesColumn = $location.search().order || 'propertyDesignation'; // default
  vm.sortPropertiesDirection = $location.search().direction || 'ASC'; // default
  vm.listPropertiesColumns = [
    { title: 'Fastigheter', column: 'propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' },
    { title: 'Antal lägenheter', column: 'numNegotiatedApartments' }
  ];

  vm.sortDistrictColumn = $location.search().order || 'district'; // default
  vm.sortDistrictDirection = $location.search().direction || 'ASC'; // default
  vm.listDistrictColumns = [
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' },
    { title: 'Antal fastigheter', column: 'noProperties' }
  ];

  vm.sortApartmentsColumn = $location.search().order || 'property.propertyDesignation'; // default
  vm.sortApartmentsDirection = $location.search().direction || 'ASC'; // default
  vm.listApartmentsColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Fastighet', column: 'property.propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' }
  ];

  queryDistrict();
  getNegotiation();
  // getApartmentsSummary(id);

  // Keep it simple - fetch the properties when navigating to the properties step
  // instead of i.e fetching every time a districts checkbox is checked
  $scope.$on('$routeUpdate', function() {
    if ($location.search().step === 'properties') {
      vm.propertiesPageOffset = 0; // always show first items
      // aFlag is used because when we filter the result from queryProperties(),
      // we don't want selectedPropertyIds = {}; to be reseted.
      // We want reset only when the route is changed and response.count != vm.propertyTotalCount.
      vm.propertyFlag = true;
      queryProperties();
    }

    if ($location.search().step === 'apartments') {
      vm.apartmentsPageOffset = 0;
      vm.apartmentsFlag = true;
      queryApartments();
    }
  });

  ////////////

  function save() {
    vm.saving = true;
    Blocking.save(vm.blocking)
      .$promise.then(
        // eslint-disable-next-line no-unused-vars
        function(response) {
          $location.search({}); // remove parameters set by wizard
          $location.path('/negotiations/' + vm.blocking.negotiationId).search('view', 'agreements');
          Flash.set('Fastigheter i förhandlingen har strandats', 'success');
        })
      .catch(ErrorHandler('Misslyckades att stranda förhandlingen.'))
      .finally(function() {
        vm.saving = false;
      });
  }

  function gotoNextStep() {
    vm.gotoNext = true;
  }

  function getNegotiation() {
    return Negotiation.get({
      id: $routeParams.id
    })
      .$promise.then(function(response) {
        vm.negotiation = response;
      })
      .catch(ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function() {
        vm.readyNegotiation = true;
      });
  }

  function queryDistrict(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.districtPageOffset = 0;
    }
    return Negotiation.queryDistrict({
      id: $routeParams.id,
      limit: vm.districtPageLimit,
      offset: vm.districtPageOffset,
      order: vm.sortDistrictColumn,
      direction: vm.sortDistrictDirection,
      'search[municipality]': vm.inputDistrictTextMunicipality,
      'search[district]': vm.inputDistrictTextDistrict,
      unassigned: true
    })
      .$promise.then(function(response) {
        vm.districts = response.rows;
        vm.districtTotalCount = response.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta område.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function queryProperties(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.propertiesPageOffset = 0;
    }
    return Negotiation.queryProperty(
      {
        id: $routeParams.id,
        limit: vm.propertiesPageLimit,
        offset: vm.propertiesPageOffset,
        order: vm.sortPropertiesColumn,
        direction: vm.sortPropertiesDirection,
        'search[propertyDesignation]': vm.inputPropertiesTextProperty,
        'search[municipality]': vm.inputPropertiesTextMunicipality,
        'search[district]': vm.inputPropertiesTextDistrict,
      },
      {
        selectedDistrictIds: vm.blocking.selectedDistrictIds,
        isAllDistrictsSelected: vm.blocking.isAllDistrictsSelected,
        unassigned: true
      }
    )
      .$promise.then(function(response) {
        // If the count of properties has changed from one query to the next,
        // then the user has gone back to the district selector and chosen a different set
        // of districts (or we've never loaded the properties before).
        // When this happens, we need to reset the selection status, because otherwise the
        // "master select box" will not detect the everything/nothing/partial selection states
        // properly, because it'll have the wrong idea of what the full selection is.
        if (vm.propertyFlag && vm.propertyTotalCount !== response.count) {
          vm.blocking.selectedPropertyIds = {};
          vm.propertyFlag = false;
        }
        vm.properties = response.rows;
        vm.propertyTotalCount = response.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta fastigheter.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  function queryApartments(resetOffset) {
    vm.ready = false;
    if (resetOffset) {
      vm.apartmentsPageOffset = 0;
    }
    return Negotiation.queryApartment(
      {
        id: $routeParams.id,
        limit: vm.apartmentsPageLimit,
        offset: vm.apartmentsPageOffset,
        order: vm.sortApartmentsColumn,
        direction: vm.sortApartmentsDirection,
        'search[nationalNo]': vm.inputApartmentTextNationalApartmentNo,
        'search[ownerNo]': vm.inputApartmentTextApartmentNo,
        'search[streetAddress]': vm.inputApartmentTextStreetAddress,
        'search[property.propertyDesignation]': vm.inputApartmentTextPropertyDesignation,
        'search[municipality]': vm.inputApartmentTextMunicipality,
        'search[district]': vm.inputApartmentTextDistrict,
        unassigned: true
      },
      {
        selectedDistrictIds: vm.blocking.selectedDistrictIds,
        selectedPropertyIds: vm.blocking.selectedPropertyIds,
        isAllDistrictsSelected: vm.blocking.isAllDistrictsSelected,
        isAllPropertiesSelected: vm.blocking.isAllPropertiesSelected
      }
    )
      .$promise.then(function(response) {
        // If the count of properties has changed from one query to the next,
        // then the user has gone back to the district selector and chosen a different set
        // of districts (or we've never loaded the properties before).
        // When this happens, we need to reset the selection status, because otherwise the
        // "master select box" will not detect the everything/nothing/partial selection states
        // properly, because it'll have the wrong idea of what the full selection is.
        if (vm.apartmentsFlag && vm.apartmentTotalCount !== response.count) {
          vm.blocking.selectedApartmentIds = {};
          vm.apartmentsFlag = false;
        }
        vm.apartments = response.rows;
        vm.apartmentTotalCount = response.count;
      })
      .catch(ErrorHandler('Misslyckades att hämta lägenheter.'))
      .finally(function() {
        vm.ready = true;
      });
  }
}

})();