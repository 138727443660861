(function () {
'use strict';

angular.module('hyresgastApp').controller('ListBlockingPropertiesCtrl', ListBlockingPropertiesCtrl);

function ListBlockingPropertiesCtrl($scope, $route, $routeParams, $location, $timeout,
  Auth, Blocking, Flash, ErrorHandler) {
  var vm = this;

  vm.pageOffset = 0;
  vm.pageLimit = 30;
  vm.delete = destroy;
  vm.getProperties = getProperties;

  vm.listColumns = [
    { title: 'Fastigheter', column: 'propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'LM distrikt', column: 'district' },
    { title: 'Antal lägenheter', column: 'numNegotiatedApartments' }
  ];
  vm.sortColumn = vm.listColumns.some(function(c) {
    return c.column === $location.search().order;
  }) ? $location.search().order : 'propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.pageOffset = 0;
      getProperties();
    }
  });

  getProperties();

  function destroy(property) {
    // if select subpage is opened close it so it will updated
    // with correct list when re-opened
    $scope.$parent.vm.editPropertiesMode = false;
    vm.deleting = true;

    let params = {
      negotiationId: $routeParams.negotiationId,
      blockingId: $routeParams.id,
      propertiesIds: property ? [property.id] : getIdsFromTicked(vm.selectedProperties),
    };

    Blocking.deleteProperty(params).$promise.then(() => {
      vm.selectedProperties = {};
      vm.properties = vm.properties.filter(x => !params.propertiesIds.includes(x.id));
      vm.pageOffset = 0;
      vm.getPropertiesVirtual();
      vm.propertiesTotalCount -= params.propertiesIds.length;
      $scope.$parent.vm.blocking.numProperties = vm.propertiesTotalCount;
      // i.e interested in notAssignedCount in negotiations
      // object to know if to show or hide buttons
      $scope.$parent.vm.getNegotiation();
      vm.initialTotalCount = vm.propertiesTotalCount;
      if (vm.initialTotalCount === 0) {
        $scope.$parent.vm.editPropertiesMode = true;
      }
      Flash.set('Lyckades att ta bort fastighet', 'success');
    }).catch(new ErrorHandler('Misslyckades att ta bort fastighet.'))
      .finally(() => { vm.deleting = false; });
  }

  vm.getPropertiesVirtual = () => {
    vm.propertiesVirtual = vm.properties.slice(vm.pageOffset, vm.pageOffset + vm.pageLimit);
  };
  function getProperties(filterMode) {
    vm.ready = false;
    if (filterMode) {
      vm.pageOffset = 0;
    }
    return Blocking.queryProperty({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id,
      limit: 2000,
      offset: 0,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[propertyDesignation]': vm.inputPropertiesTextProperty,
      'search[ownerName]': vm.inputPropertiesTextOwner,
      'search[municipality]': vm.inputPropertiesTextMunicipality,
      'search[district]': vm.inputPropertiesTextDistrict,
      include: ['owners']
    })
      .$promise.then(function(response) {
        vm.properties = response.result.rows;
        vm.propertiesTotalCount = response.result.count;
        // Save initial count of records, to be distinguished from the current count after
        // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
        if (filterMode) {
          vm.offset = 0;
        } else {
          vm.initialTotalCount = vm.propertiesTotalCount;
        }
        // open the select subpage when properties' list is empty
        if (vm.initialTotalCount === 0) {
          $scope.$parent.vm.editPropertiesMode = true;
        }

        vm.selectedProperties = createTickedObject(vm.properties, false);
        vm.toggledAll = false;
        vm.getPropertiesVirtual();
      })
      .catch(new ErrorHandler('Misslyckades att hämta fastighetsbestånd.'))
      .finally(function() {
        vm.ready = true;
      });
  }

  //TODO: flytta til fe-common
  /**
   * converts ticked object to array of ids
   * @param {Object} ticked
   * @returns {Array<number>}
   */
  const getIdsFromTicked = ticked => Object.keys(ticked).filter(k => ticked[k]).map(id => parseInt(id));

  /**
   * creates ticked object from collection
   * @param {Array} rows
   * @param {boolean} [initialValue]
   * @returns {Object}
   */
  const createTickedObject = (rows, initialValue = true) => rows.reduce((acc, el) => {
    acc[el.id] = initialValue;
    return acc;
  }, {});

  vm.hasSelectedProperties = () =>
    vm.selectedProperties ? getIdsFromTicked(vm.selectedProperties).length : false;

  vm.toggleProperty = () => {
    $timeout(() => {
      vm.toggledAll = getIdsFromTicked(vm.selectedProperties).length === vm.properties.length;
    }, 500);
  };

  vm.toggleAllProperties = () => {
    if (getIdsFromTicked(vm.selectedProperties).length < vm.properties.length) {
      vm.selectedProperties = createTickedObject(vm.properties);
    } else {
      vm.selectedProperties = {};
    }
  };
}
})();