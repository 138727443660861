(function () {
'use strict';

angular.module('hyresgastApp').controller('ListAgreementApartmentsCtrl', ListAgreementApartmentsCtrl);

function ListAgreementApartmentsCtrl($scope, $routeParams, $location, $timeout,
  Agreement, Flash, ErrorHandler) {
  var vm = this;
  vm.apartmentsPageLimit = 30;
  vm.apartmentsPageOffset = 0;

  vm.delete = destroy;
  vm.getApartments = getApartments;
  vm.getMonthlyRent = getMonthlyRent;
  vm.getNewMonthlyRent = getNewMonthlyRent;
  vm.getChangeDescription = getChangeDescription;

  vm.listColumns = [
    { title: 'Nationellt lägenhetsnr', column: 'nationalNo' },
    { title: 'Lägenhetsnr', column: 'ownerNo' },
    { title: 'Gatuadress', column: 'streetAddress' },
    { title: 'Fastighet', column: 'property.propertyDesignation' },
    { title: 'Kommun', column: 'municipality' },
    { title: 'Ingående hyra (kr/månad)', column: 'currentMonthlyRent' }
  ];
  vm.sortColumn = vm.listColumns.some(function(c) {
    return c.column === $location.search().order;
  })
    ? $location.search().order
    : 'property.propertyDesignation'; // default
  vm.sortDirection = $location.search().direction || 'ASC'; // default

  $scope.$on('$routeUpdate', function() {
    if ($location.search().order) {
      // reset offset when sorting
      vm.apartmentsPageOffset = 0;
      getApartments();
    }
  });

  getApartments();

  /////////

  function destroy(apartment) {
    // if wizard is open close it so it will updated
    // with correct list when re-opened
    $scope.$parent.vm.editApartmentMode = false;
    vm.deletingApartment = true;

    let params = {
      negotiationId: $routeParams.negotiationId,
      agreementId: $routeParams.id,
      apartmentsIds: apartment ? [apartment.id] : getIdsFromTicked(vm.selectedApartments),
    };

    Agreement.deleteApartment(params).$promise.then(() => {
      vm.selectedApartments = {};
      vm.apartments = vm.apartments.filter(x => !params.apartmentsIds.includes(x.id));
      vm.apartmentsPageOffset = 0;
      vm.getApartmentsVirtual();
      vm.apartmentsTotalCount -= params.apartmentsIds.length;
      $scope.$parent.vm.agreement.numApartments = vm.apartmentsTotalCount;
      // i.e interested in notAssignedCount in negotiations
      // object to know if to show or hide buttons
      $scope.$parent.vm.getNegotiation($routeParams.negotiationId);
      vm.initialTotalCount = vm.apartmentsTotalCount;
      if (vm.initialTotalCount === 0) {
        $scope.$parent.vm.editApartmentMode = true;
      }
      Flash.set('Lyckades ta bort lägenhet', 'success');
    }).catch(new ErrorHandler('Misslyckades att ta bort fastighet.'))
      .finally(() => { vm.deletingApartment = false });
  }

  vm.getApartmentsVirtual = () => {
    vm.apartmentsVirtual = vm.apartments.slice(vm.apartmentsPageOffset, vm.apartmentsPageOffset + vm.apartmentsPageLimit);
  };
  function getApartments(filterMode) {
    vm.readyApartments = false;
    return Agreement.queryApartment({
      negotiationId: $routeParams.negotiationId,
      id: $routeParams.id,
      limit: 2000,
      offset: 0,
      order: vm.sortColumn,
      direction: vm.sortDirection,
      'search[nationalNo]': vm.inputTextNationalApartmentNo,
      'search[ownerNo]': vm.inputTextApartmentNo,
      'search[streetAddress]': vm.inputTextStreetAddress,
      'search[property.propertyDesignation]': vm.inputTextPropertyDesignation,
      'search[municipality]': vm.inputTextMunicipality
    })
      .$promise.then(function(response) {
        let agreement = $scope.$parent.vm.agreement;
        vm.isSigningStarted = agreement
          ? agreement.scriveId && (agreement.scriveId !== 'bubkis')
          : false;
        vm.apartments = response.rows;
        vm.apartmentsTotalCount = response.count;
        // Save initial count of records, to be distingueshed from the current count after
        // unsuccessful filtering. This will affect all messages, which are dependent on totalCount = 0.
        if (filterMode) {
          vm.apartmentsPageOffset = 0;
        } else {
          vm.initialTotalCount = vm.apartmentsTotalCount;
        }
        // open the wizard when apartments' list is empty
        if (vm.initialTotalCount === 0) {
          $scope.$parent.vm.editApartmentMode = true;
        }

        vm.selectedApartments = createTickedObject(vm.apartments, false);
        vm.toggledAll = false;
        vm.getApartmentsVirtual();
      })
      .catch(ErrorHandler('Misslyckades att hämta fastighetsbestånd.'))
      .finally(function() {
        vm.readyApartments = true;
      });
  }

  function getMonthlyRent(apartment) {
    if (apartment.currentMonthlyRent) {
      return apartment.currentMonthlyRent;
    } else {
      return parseFloat(apartment.currentYearlyRent / 12).toFixed(2);
    }
  }

  function getNewMonthlyRent(apartment) {
    if (apartment.newMonthlyRent) {
      return apartment.newMonthlyRent;
    } else {
      return apartment.newYearlyRent / 12;
    }
  }

  function getChangeDescription(apartment) {
    if (apartment.changeDescription) {
      return apartment.changeDescription;
    } else {
      const monthlyRent = getMonthlyRent(apartment);
      const diff = getNewMonthlyRent(apartment) - monthlyRent;
      const increasePercentage = diff === 0 ? 0 : diff / monthlyRent * 100;
      const numberParsed = parseFloat(increasePercentage).toFixed(2);
      return numberParsed + '%';
    }
  }

  //TODO: flytta til fe-common
  /**
   * converts ticked object to array of ids
   * @param {Object} ticked
   * @returns {Array<number>}
   */
  const getIdsFromTicked = ticked => Object.keys(ticked).filter(k => ticked[k]).map(id => parseInt(id));

  /**
   * creates ticked object from collection
   * @param {Array} rows
   * @param {boolean} [initialValue]
   * @returns {Object}
   */
  const createTickedObject = (rows, initialValue = true) => rows.reduce((acc, el) => {
    acc[el.id] = initialValue;
    return acc;
  }, {});

  vm.hasSelectedApartments = () => vm.selectedApartments
    ? getIdsFromTicked(vm.selectedApartments).length
    : false;
  vm.toggleApartment = () => {
    $timeout(() => {
      vm.toggledAll = getIdsFromTicked(vm.selectedApartments).length === vm.apartments.length;
    }, 500);
  };
  vm.toggleAllApartments = () => {
    if (getIdsFromTicked(vm.selectedApartments).length < vm.apartments.length) {
      vm.selectedApartments = createTickedObject(vm.apartments);
    } else {
      vm.selectedApartments = {};
    }
  };
}

})();