(function () {
'use strict';

angular.module('hyresgastApp').controller('ShowNegotiationCtrl', ShowNegotiationCtrl);

function ShowNegotiationCtrl(
  $scope, $location, $routeParams, $uibModal, $route,
  Negotiation, Seenby,
  NegotiationStates, NegotiationCallStates, ObjectTypes, NegotiationTypes, FeatureConfiguration,
  Flash, HyUtils, SubPage, Auth, ErrorHandler
) {
  var vm = this;

  vm.NegotiationStates = NegotiationStates;
  vm.NegotiationCallStates = NegotiationCallStates;
  vm.NegotiationTypes = NegotiationTypes;
  vm.FeatureConfiguration = FeatureConfiguration;


  vm.tablist = [
    { title: 'Översikt', path: '/negotiations/' + $routeParams.id },
    { title: 'Överenskommelse', path: '/negotiations/' + $routeParams.id + '?view=agreements' },
    { title: 'Dokument', path: '/negotiations/' + $routeParams.id + '?view=documents' },
    { title: 'Historik', path: '/negotiations/' + $routeParams.id + '?view=history' },
  ];

  if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
    vm.tablist.splice(2, 0, { title: 'Möten', path: '/negotiations/' + $routeParams.id + '?view=meetings' });
  }

  Auth.heartbeat().then(() => {
    vm.isHgf = Auth.isHgfUser();
    if (vm.isHgf) {
      vm.tablist.push({ title: 'Anteckningar', path: '/negotiations/' + $routeParams.id + '?view=blog' });
      vm.tablist.push({ title: 'Interna dokument', path: '/negotiations/' + $routeParams.id + '?view=artifacts' });
    }
  });

  vm.tablistForNewProperties = [
    { title: 'Översikt', path: '/negotiations/' + $routeParams.id },
    { title: 'Överenskommelse', path: '/negotiations/' + $routeParams.id + '?view=agreements' },
    { title: 'Dokument', path: '/negotiations/' + $routeParams.id + '?view=documents' },
    { title: 'Historik', path: '/negotiations/' + $routeParams.id + '?view=history' },
  ];

  if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
    vm.tablistForNewProperties.splice(2, 0, { title: 'Möten', path: ('/negotiations/' + $routeParams.id + '?view=meetings') });
  }

  vm.importRentlist = importRentlist;
  vm.forgeRentlist = forgeRentlist;
  vm.showWithoutRentList = showWithoutRentList;
  vm.gotoMotiv = () => $location.path('/negotiations/' + vm.negotiation.id).search('view', 'motiv');
  vm.addItems = () => $location.path('/negotiations/' + vm.negotiation.id).search('view', 'addproperties');

  vm.findNegotiationRole = HyUtils.findNegotiationRole;

  $scope.$on('$routeUpdate', function() {
    // avoid unecessary calling of getCurrentSubpage() when sorting
    if (!$location.search().order) {
      getCurrentSubpage();
      getNegotiation();
    }
  });

  vm.isRentListFileType = isRentListFileType;
  function isRentListFileType (fileType) {
    return (fileType === 35 || fileType === 36);
  }

  getCurrentSubpage();
  getNegotiation();

  vm.getNegotiation = getNegotiation;

  /////////

  function getRentlistStatus(summary) {
    let status = {};

    status.missing = summary.properties === 0 || summary.lacksRentList > 0;

    if (status.missing) {
      status.partiallyMissing = summary.lacksRentList < summary.properties;
      if (status.partiallyMissing) {
        status.missingCount = summary.lacksRentList;
      }
    }

    let countRealEstate = vm.negotiation.canSkipRentlist ? summary.properties : summary.apartments;
    status.agreementsStarted = countRealEstate > 0
      && (vm.negotiation.realEstateStatus.notAssignedCount < countRealEstate);

    status.emptyRentlist = summary.apartments === 0;

    return status;
  }

  function setStatuses() {
    vm.negotiation.rentlistStatus = getRentlistStatus(vm.negotiation.realEstateSummary);

    let overviewOk = vm.negotiation.canSkipRentlist || !vm.negotiation.rentlistStatus.missing;
    if (!vm.isNewlyConstructedApartment) {
      overviewOk &= vm.negotiation.realEstateSummary.lacksDemands === 0;
    } else {
      //ett hack, men jag är för lat för att kolla efter isNewApartments överallt
      vm.negotiation.realEstateSummary.lacksDemands = 0;
    }
    let agreementsOk = vm.negotiation.rentlistStatus.agreementsStarted;
    if (vm.negotiation.canSkipRentlist) {
      agreementsOk = vm.negotiation.realEstateSummary.properties > 0
        && (vm.negotiation.realEstateStatus.notAssignedCount < vm.negotiation.realEstateSummary.properties)
    }
    vm.tablist[0].statusIcon = overviewOk ? 'ok' : 'warning';
    vm.tablist[1].statusIcon = agreementsOk ? 'ok' : 'warning';

    vm.hasApartments = vm.negotiation.realEstateSummary.uploadedApartmentsCount > 0;
    vm.apartmentsOrProperties = vm.negotiation.canSkipRentlist ? vm.negotiation.realEstateSummary.properties :
      vm.negotiation.realEstateSummary.apartments;

    vm.unfinishedAgreementsCount = vm.negotiation.unfinishedAgreementsCount.count;
  }

  function getNegotiation() {
    vm.negotiationQueryPromise = Negotiation.get({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.negotiation = response;

        vm.negotiation.hgfParticipants.forEach(d => d.roleObj = vm.findNegotiationRole(d.negotiationsUsers.role));
        vm.negotiation.externalParticipants.forEach(d => d.roleObj = vm.findNegotiationRole(d.negotiationsUsers.role));
        vm.negotiation.delegationParticipants.forEach(
          d => d.roleObj = vm.findNegotiationRole(d.negotiationsUsers.role));
        vm.negotiation.delegationInvitations.forEach(d => d.roleObj = vm.findNegotiationRole(d.role));

        let partyFilter = (party) => (participant) => participant.roleObj.party === party;

        vm.negotiation.hgfSide = vm.negotiation.hgfParticipants
          .concat(vm.negotiation.delegationParticipants.filter(partyFilter(20)));
        vm.negotiation.faSide = vm.negotiation.externalParticipants
          .concat(vm.negotiation.delegationParticipants.filter(partyFilter(0)));

        vm.negotiation.chiefNegotiatorHGF = vm.negotiation.hgfSide.filter(participant => participant.isChief)[0];
        vm.negotiation.chiefNegotiatorExternal = vm.negotiation.faSide.filter(participant => participant.isChief)[0];

        vm.negotiation.isRentListMissing =
          vm.negotiation.realEstateSummary.lacksRentList < vm.negotiation.realEstateSummary.properties;

        vm.apartmentMode = (vm.negotiation.type === NegotiationTypes.STANDARD_APARTMENT ||
                            vm.negotiation.type === NegotiationTypes.REBUILD_APARTMENT ||
                            vm.negotiation.type === NegotiationTypes.UTILITY_VALUE_APARTMENT ||
                          (vm.negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT &&
                           vm.negotiation.isRentListMissing));

        vm.isNewlyConstructedProperty = vm.negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_PROPERTY;
        vm.isNewlyConstructedApartment = vm.negotiation.type === NegotiationTypes.NEWLY_CONSTRUCTED_APARTMENT;
        vm.negotiation.isFinished = vm.negotiation.state === NegotiationStates.FINISHED ||
                                    vm.negotiation.state === NegotiationStates.FINISHED_OUTSIDE_PORTAL;

        setStatuses();

        return getWithoutRentList();
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandling.'))
      .finally(function() {
        vm.ready = true;
        Seenby.set({
          objectType: ObjectTypes.NEGOTIATION.code,
          objectId: $routeParams.id,
        });
      });
    return vm.negotiationQueryPromise;
  }

  vm.editComment = () => {
    HyUtils.showDefaultModal('EditCommentModalCtrl',
      'resources/agreement/rentlist/edit/edit-comment-modal.html',
      { comment: vm.negotiation.comment, multipleComments: '' },
      result => {
        if (result.delete || result.comment) {
          vm.negotiation.comment = result.delete ? '' : result.comment;
          Negotiation.saveComments([{
            negotiationId: vm.negotiation.id,
            comment: vm.negotiation.comment,
          }]);
          vm.isDirty = true;
        }
      }, null, null,
      { size: 'md' });
  };

  function getWithoutRentList() {
    return Negotiation.queryWithoutRentList({ id: $routeParams.id })
      .$promise.then(function(response) {
        vm.numWithoutRentlist = response.count;
        vm.withoutRentlist = response.rows;
      })
      .catch(new ErrorHandler('Misslyckades att hämta förhandlingar.'));
  }

  vm.finish = () => {
    let caption = 'Varning';
    let message = 'Om du väljer att avsluta förhandlingen utan att göra klart den i Förhandlarportalen, kommer den inte gå att återöppna.';
    let confirmBtn = 'Avsluta förhandling';
    let cancelBtn = 'Avbryt';

    HyUtils.showDefaultModal('ConfirmationModalWithMessageAndButtonsCtrl',
      'components/modal/confirmation/confirmationModalWithMessageAndButtons.html',
      // eslint-disable-next-line no-unused-vars
      { caption, message, confirmBtn, cancelBtn }, result => {
        Negotiation.finish({ id: $routeParams.id })
          .$promise.then(function(response) {     // eslint-disable-line no-unused-vars
            getNegotiation(); // updated with new state
            Flash.set('Lyckades att avsluta förhandling.', 'success');
          })
          .catch(new ErrorHandler('Misslyckades att avsluta förhandlingar.'))
          .finally(function() {
            vm.ready = true;
          });
      }, null, null, { size: 'md' });
  }

  vm.finishAsHgfUser = () => {
    let caption = 'Varning';
    let confirmBtn = 'Avsluta förhandling';
    let cancelBtn = 'Avbryt';
    let message = 'Du kommer inte kunna genomföra digitala överenskommelser om du stänger denna förhandling.';

    if (vm.negotiation.finishable.flag) {
      message = 'Om du väljer att avsluta förhandlingen utan att göra klart den i Förhandlarportalen, kommer den inte gå att återöppna.';
    }

    HyUtils.showDefaultModal('ConfirmationModalWithMessageAndButtonsCtrl',
      'components/modal/confirmation/confirmationModalWithMessageAndButtons.html',
      // eslint-disable-next-line no-unused-vars
      { caption, message, confirmBtn, cancelBtn }, result => {
        Negotiation.finishAsHgfUser({ id: $routeParams.id })
          .$promise.then(function(response) {     // eslint-disable-line no-unused-vars
            getNegotiation(); // updated with new state
            Flash.set('Lyckades att avsluta förhandling.', 'success');
          })
          .catch(new ErrorHandler('Misslyckades att avsluta förhandlingar.'))
          .finally(function() {
            vm.ready = true;
          });
      }, null, null, { size: 'md' });
  }

  vm.reopen = () => {
    let caption = 'Varning';
    let confirmBtn = 'Återöppna förhandling';
    let cancelBtn = 'Avbryt';
    let message = 'Är du säker?';

    HyUtils.showDefaultModal('ConfirmationModalWithMessageAndButtonsCtrl',
      'components/modal/confirmation/confirmationModalWithMessageAndButtons.html',
      // eslint-disable-next-line no-unused-vars
      { caption, message, confirmBtn, cancelBtn }, result => {
        Negotiation.reopen({ id: $routeParams.id })
          .$promise.then(function(response) {     // eslint-disable-line no-unused-vars
            getNegotiation(); // updated with new state
            Flash.set('Lyckades att återöppna förhandling.', 'success');
          })
          .catch(new ErrorHandler('Misslyckades att återöppna förhandlingar.'))
          .finally(function() {
            vm.ready = true;
          });
      }, null, null, { size: 'sm' });
  }

  function getCurrentSubpage() {
    var defaultSubPage = 'overview';
    // create a validation list against manually change of URL '?view=' parameter
    var whiteList = ['motiv', 'documents', 'history', 'agreements', 'blog', 'addproperties', 'artifacts'];
    if (vm.FeatureConfiguration.ENABLE_MEETINGS) {
      whiteList.push('meetings');
    }
    vm.currentSubpage = SubPage.set($routeParams.view, whiteList, defaultSubPage);
  }

  /**
   * displays banner and reloads negotiation
   * @param {string} message
   */
  function rentlistOkReload(message) {
    getNegotiation().then(function() {
      $scope.$broadcast('rentListImported');
    });
    Flash.set(message, 'success');
  }

  function importRentlist(amend) {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      backdrop: 'static',
      size: 'lg',
      index: 1075,
      templateUrl: 'resources/negotiation/show/modals/import-rentlist/importRentlistModal.html',
      controller: 'ImportRentlistCtrl',
      controllerAs: 'vm',
      resolve: {
        amend: () => amend,
        negotiation: function() {
          return vm.negotiation;
        }
      },
      windowClass: 'modal-content--green'
    });

    modalInstance.result.then(function(message) {
      if (message) {
        rentlistOkReload(message);
      }
    });
  }

  function forgeRentlist(amend) {

    let params = { id: $routeParams.id };

    let openForgeListModal = apartmentCount => {
      vm.ready = false;
      (vm.apartmentMode ? Negotiation.queryApartment : Negotiation.queryProperty)(params, {})
        .$promise.then(response => {
          vm.ready = true;
          HyUtils.showDefaultModal('ForgingRentlistModalCtrl',
            'resources/negotiation/show/modals/forging-rentlist/forging-rentlist-modal.html', {
              negotiation: vm.negotiation,
              negotiationRealEstates: response.rows,
              isNewProperty: vm.isNewlyConstructedApartment,
              amend,
              apartmentCount,
            }, result => {
              if (result.numImported) {
                rentlistOkReload("Lägenheter har lagts till förhandlingen");
              }
            }, null, null,
            { isGreen: true });
        });
    }

    Negotiation.countApartment(params)
      .$promise.then(response => {
        if (parseInt(response.count) > 0) {
          openForgeListModal(response.count);
        } else {
          HyUtils.askQuestion('Ingen lägenheter finns i vald fastigheter!', [ 'OK' ]);
        }
      });
  }

  function showWithoutRentList() {
    // eslint-disable-next-line no-unused-vars
    $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'lg',
      index: 1075,
      templateUrl: 'resources/negotiation/show/modals/without-rentlist/withoutRentlistModal.html',
      controller: 'WithoutRentListCtrl',
      controllerAs: 'vm',
      resolve: {
        withoutRentlist: function() {
          return vm.withoutRentlist;
        },
        isNewlyConstructedApartment: function() {
          return vm.isNewlyConstructedApartment;
        }
      },
      windowClass: 'modal-content--green'
    });
  }

  /**
 *
 * Shows edit negotiation type and counterpart popup
 *
 */
  vm.editNegotiation = () => {
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation/edit/edit-negotiationType-modal.html',
      controller: 'EditNegotiationTypeModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        negotiationObject: function() {
          return vm.negotiation;
        },
        isFreeAgreement: () => false,
      }
    });
    modalInstance.result.then(function(negotiation) {
      vm.negotiation.name = negotiation.name;
      vm.negotiation.type = negotiation.type;
    });
  }

  /**
 *
 * Shows Warning and confirmation popup for the checkbox
 *
 */
  vm.confirmNegotiationWithoutApartments = () => {
    if (vm.negotiation.isFinished || vm.negotiation.rentlistStatus.agreementsStarted) {
      return;
    }
    var modalInstance = $uibModal.open({
      animation: true,
      ariaLabelledBy: 'modal-title-top',
      ariaDescribedBy: 'modal-body-top',
      size: 'sm',
      index: 1075, // position above tooltip
      templateUrl: 'resources/negotiation/edit/skip-rentlist-modal.html',
      controller: 'SkipRentlistModalCtrl',
      controllerAs: 'vm',
      windowClass: 'modal-content--white-border',
      resolve: {
        negotiationObject: function() {
          return vm.negotiation;
        },
        hasApartments: function() {
          return vm.hasApartments;
        },
      }
    });
    modalInstance.result.then(function() {
      getNegotiation();
    });
  }

  vm.deleteNegotiation = Negotiation.delete;
  vm.warningMessageDelete = `<p>Du kommer nu att <b><u>RADERA</u></b> hela förhandlingen som är skapad från databasen.
Detta val går ej att ångra eller återskapa, och om du tar bort förhandlingen felaktigt måste du själv återskapa förhandlingen på nytt igen.
</p>
<p>Önskar du endast återkalla vissa fastigheter i en förhandling då det blivit fel, ska du välja avbryt.</p>
<p><b>Instruktion om återkallelse finns i lathund på "Mina sidor"</b></p>`;

  vm.unforgeRentlist = unassignedOnly => {
    vm.busy = true;
    Negotiation.unforgeRentlist({
      id: vm.negotiation.id,
      unassignedOnly,
    // eslint-disable-next-line no-unused-vars
    }).$promise.then(result => {
      vm.busy = false;
      $route.reload();
    }).catch(() => {
      new ErrorHandler('Misslyckades att avvälj lägenheter');
    });
  };

}
})();