(function () {
'use strict';

angular.module('hyresgastApp').directive('objectFooter', objectFooter);

//footer for every object that has ID
//the only feature now is object deletion

function objectFooter($routeParams,
  Flash, HyUtils) {
  var directive = {
    restrict: 'E',
    templateUrl: 'components/object-footer/object-footer.html',
    scope: {
      objectName: '@',

      warningMessageDelete: '@',
      fDelete: '&',
      redirectOnDelete: '@',
    },
    // eslint-disable-next-line no-unused-vars
    link: (scope, element, attrs) => {
      scope.deleteObject = () => {

        let customMessage = scope.warningMessageDelete && scope.warningMessageDelete.length;

        let message = customMessage ? scope.warningMessageDelete : 'Är du säker?';
        let params = customMessage ? {} : { size: 'xsm' };

        HyUtils.showDefaultModal('WarningConfirmationModalCtrl',
          'components/modal/confirmation/warningConfirmationModal.html', {
            message,
            button: 'Ta bort',
            critical: true,
          // eslint-disable-next-line no-unused-vars
          }, result => {
            scope.fDelete()({ id: $routeParams.id })
            // eslint-disable-next-line no-unused-vars
              .$promise.then(result => {
                Flash.set(`Lyckades att ta bort ${scope.objectName}`, 'success');
                // eslint-disable-next-line angular/window-service, no-undef
                window.location.href = scope.redirectOnDelete && scope.redirectOnDelete
                  ? scope.redirectOnDelete
                  : '/';
              }).catch(err =>
                Flash.set(err.status === 501
                  ? err.data.message
                  : `Misslyckades att ta bort ${scope.objectName}`, 'error'));
          }, null, null, params);
      };
    }
  };
  return directive;
}
})();